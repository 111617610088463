<template>
  <div>
    <CRow>
      <CCol lg="7">
        <StudentMappingTable />
      </CCol>
      <CCol lg="5">
        <AddStudent />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import StudentMappingTable from "./StudentMappingTable";
import AddStudent from "./AddStudent";
export default {
  name: "Teacher",
  components: { StudentMappingTable, AddStudent },
};
</script>
